var API_PATH = "api/v1.1";
var SERVER_URL = `http://localhost:3000/${API_PATH}`

if(process.env.NODE_ENV === "production") {
    var SERVER_URL = `https://server.locstat.app/${API_PATH}`
}

const BackendService = {
    getListUsers: async (accessToken: any) => {
        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
            
            fetch(`${SERVER_URL}/get-users`, {
                method: "GET",
                headers: headers,
                // mode: 'no-cors',
                // redirect: 'follow',
                credentials: "same-origin",
            })
            .then(response => {
                // Check if the response status is OK (200)
                console.log(response)
                if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // Parse the response JSON data
                return response.json();
            })
            .then(data => {
                // Handle the data here
                console.log('Data received:', data);
                return resolve(data)
            })
            .catch(error => {
                // Handle any errors here
                console.error('Fetch error:', error);
                return reject(error)
            });
        });
    },
    checkAdmin: async (accessToken: any) => {
        return new Promise((resolve, reject) => {
            const headers = {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            };
            
            fetch(`${SERVER_URL}/check-admin`, {
                method: "GET",
                headers: headers,
                credentials: "same-origin",
            })
            .then(response => {
                // Check if the response status is OK (200)
                console.log(response)
                if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // Parse the response JSON data
                return response.json();
            })
            .then(data => {
                // Handle the data here
                console.log('Data received:', data);
                return resolve(data)
            })
            .catch(error => {
                // Handle any errors here
                console.error('Fetch error:', error);
                return reject(error)
            });
        });
    }
}

export default  BackendService