import { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import BackendService from "../Services/BackendService";
import Map from "./Map";
import MyMapComponent from "./Map";
import SimpleMap from "./Map";
import React from "react";
import { socket } from "../socket";
import { ConnectionState } from "../components/ConnectionState";
import ListClients from "../components/ListClients";
import { ConnectionManager } from "../components/ConnectionManager";
import { User, getAuth } from "firebase/auth";

export default function RealtimePage(props: any) {

    const [listUsers, setListUsers] = useState([]);
    const key = 'AIzaSyDeCvTgsqDaWbgQshlIk-Ww_EiAqJ7yED8'
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [clients, setClients] = useState([]);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    var timer = useRef<TimerHandler>(null)

    useEffect(() => {
        // Add an event listener to check for changes in the authentication state
        const unsubscribe = getAuth().onAuthStateChanged((user) => {
          if (user) {
            // User is signed in.
            console.log("Current user", user);
            
            setCurrentUser(user);
          } else {
            // User is signed out.
            setCurrentUser(null);
          }
        });
    
        // Clean up the listener when the component unmounts
        return () => unsubscribe();
      }, []);
    
    useEffect(() => {

        const timer = setInterval(() => {
            //console.log("Start get clients: ");
            socket.emit('GetListClients')
        }, 2000)
        

        function onConnect() {
            console.log("On connect");
            setIsConnected(true);
            socket.emit('GetListClients')
            // Send emit event
            socket.emit("admin", {
                id: "webadmin",
                firstName: "Web Admin",
            })
        }

        function onDisconnect() {
            setIsConnected(false);
            setClients([])
        }

        function onReceiveListClients(value: any) {
            //console.log("On get clients: ", value);
            setClients(value)
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('ClientsUpdated', onClientsChanged);
        socket.on('ReceiveListClients', onReceiveListClients)

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            clearInterval(timer)
        };
    }, []);

    const onClientsChanged = (clients: []) => {
        console.log("On clients updated", clients);
        setClients(clients)
    }

    const updateClients = () => {
        socket.emit("GetListClients")
    }

    return (
        <div>
            <div className="App">
                <ConnectionState isConnected={ isConnected } />
                <ListClients clients={clients}></ListClients>
                <ConnectionManager />
            </div>
            <SimpleMap clients={clients} />
        </div>
    )
}