import React, { useEffect, useState } from "react";
import BackendService from "../Services/BackendService";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    PaginationState,
    getPaginationRowModel
  } from '@tanstack/react-table'
import { getAuth } from "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import _ from "lodash";
  

export default function UsersPage(props: any) {

    const [listUsers, setListUsers] = useState([]);
    const auth = useAuth()

    useEffect(() => {
      if(!_.isEmpty(auth.accessToken))
      {
        let accessToken = auth.accessToken
        console.log("Access token: ", accessToken);
        BackendService.getListUsers(accessToken)
        .then((response: any) => {
          console.log(response.data);
          setListUsers(response.data)
        })
      }
    }, [auth.accessToken])

    return (
      <>
        <p className="page-title">List users</p>
        <ReactTable listUsers={listUsers}/>
      </>
    )
}

type User = {
  fbid: string,
  email: string,
  password: string,
  countryId: number,
  phone: string,
  firstName: string,
  lastName: string,
  photoUrl: string,
  acceptTerms: Date,
  role: string,
  verificationToken: string,
  verified: Date,
  resetToken: string,
  resetTokenExpires: Date,
  passwordReset: Date,
  lastLogin: Date
}

const columnHelper = createColumnHelper<User>()

const columns = [
  columnHelper.accessor('firstName', {
    header: () => <span>Name</span>,
    cell: info => info.getValue(),
    // footer: info => info.column.id,
  }),
  columnHelper.accessor(row => row.email, {
    id: 'Email',
    cell: info => <i>{info.getValue()}</i>,
    header: () => <span>Email</span>,
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('phone', {
    header: () => 'Phone',
    cell: info => info.renderValue(),
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('countryId', {
    header: () => <span>Country</span>,
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('photoUrl', {
    header: () => <span>Avatar</span>,

    cell: (props) => (
      <div className="table-avatar mr-3">
        <img
          className="table-avatar-img"
          src={props.row.original.photoUrl ? props.row.original.photoUrl : require("../assets/avatar.png")} alt={""}
        />
      </div>
    )
    // footer: info => info.column.id,
  }),
  columnHelper.accessor('lastLogin', {
    header: () => <span>Last Login</span>,
    // footer: info => info.column.id,
  }),
]

function ReactTable(props: any) {
  const [data, setData] = React.useState(() => [...props.listUsers])
  const rerender = React.useReducer(() => ({}), {})[1]

  useEffect(() => {
    setData(props.listUsers)
  }, [props])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <div className="p-2">
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>

      <div className="pagination flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <span>Page</span>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="go-to-page flex items-center gap-1">
          Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div className="h-4" />
      <button onClick={() => rerender()} className="border p-2">
        Rerender
      </button>
    </div>
  )
}